import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import ReactMarkdown from "react-markdown";
import Breadcrumbs from "../components/Breadcrumbs";
import ContactForm from "../components/Contactformulier";
import Layout from "../components/layout";
import RelatedCases from "../components/RelatedCases";
import SEO from "../components/seo";

export const ServiceItemPageTemplate = ({ post, breadcrumbs }) => {
  return (
    <div className="container mx-auto px-6  text-white flex flex-col  ">
      <div className="max-w-4xl mb-12 ">
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <p className="mt-6 prose lg:prose-2xl text-white">
          {post.lead_paragraph}
        </p>
        <ReactMarkdown
          className="prose lg:prose-lg text-white mt-6"
          source={post.body.split(`](assets`).join(`](/assets`)}
        />

        {post.why_gladior && (
          <div className="netlify-html text-white">
            <h3>Wat Gladior voor jou kan betekenen</h3>
            <ReactMarkdown
              className="prose lg:prose-lg text-white mt-6"
              source={post.why_gladior.split(`](assets`).join(`](/assets`)}
            />
          </div>
        )}
      </div>
      {post.related_cases && post.related_cases.length > 0 && (
        <>
          <h3 className="mt-20 mb-10 text-2xl sm:text-3xl md:text-3xl xl:text-3xl leading-tight  text-white font-bold mb-3 font-display ">
            Our related cases
          </h3>
          <RelatedCases relatedCases={post.related_cases} />
        </>
      )}
    </div>
  );
};

const ServiceItemPage = ({ data }) => {
  const { serviceItemYaml: post, allServiceCategoryYaml } = data;

  let breadcrumbs = [];

  breadcrumbs.push({
    name: "Services",
    slug: "/services/",
  });

  if (allServiceCategoryYaml && allServiceCategoryYaml.edges.length > 0) {
    allServiceCategoryYaml.edges.forEach((cat) => {
      let services = cat.node.services;
      services.forEach((service) => {
        if (service.service_item_categories) {
          service.service_item_categories.forEach((category) => {
            if (category.service_items) {
              category.service_items.forEach((serItem) => {
                if (serItem.id === post.id) {
                  breadcrumbs.push({
                    name: cat.node.id,
                    slug: cat.node.fields.slug,
                  });
                  breadcrumbs.push({
                    name: service.id,
                    slug: service.fields.slug,
                  });
                  breadcrumbs.push({
                    name: category.id,
                    slug: category.fields.slug,
                  });
                  breadcrumbs.push({
                    name: serItem.id,
                  });
                }
              });
            }
          });
        }
      });
    });
  }

  return (
    <>
      <SEO
        description={post.seo.meta_description}
        pathname={post.path}
        title={post.seo.meta_title}
      />
      <Layout
        heroBackgroundImage={post.hero_background_image}
        title={post.title}
      >
        <main className="bg-gladior-dark ">
          <section className="container mx-auto px-6 py-10  max-w-7xl  ">
            <ServiceItemPageTemplate post={post} breadcrumbs={breadcrumbs} />
          </section>
          <ContactForm />
        </main>
      </Layout>
    </>
  );
};

ServiceItemPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ServiceItemPage;

export const serviceItemPageQuery = graphql`
  query ServiceItemPage($id: String!) {
    allServiceCategoryYaml #       } #         } #           } #             service_items: { elemMatch: { id: { eq: $id } } } #             id: {} #           elemMatch: { #         service_item_categories: { #       elemMatch: { #     services: { #   filter: { # (
    #     }
    #   }
    # )
    {
      edges {
        node {
          id
          fields {
            slug
          }
          services {
            fields {
              slug
            }
            id
            service_item_categories {
              id
              fields {
                slug
              }
              service_items {
                id
                fields {
                  slug
                }
              }
            }
          }
        }
      }
    }

    serviceItemYaml(id: { eq: $id }) {
      id
      lead_paragraph
      seo {
        meta_description
        meta_title
      }
      hero_background_image {
        childImageSharp {
          fluid(quality: 90, maxWidth: 4160) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      related_cases {
        about_customer
        body
        description
        id
        lead_paragraph
        quote {
          organisation
          person
          quote
        }
        seo {
          meta_description
          meta_title
        }
        services
        subtitle
        templateKey
        title
        fields {
          slug
        }
        cover_image {
          childImageSharp {
            fixed(height: 256) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
      }
      thumbnail {
        childImageSharp {
          fluid(maxWidth: 2048, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      title
      fields {
        slug
      }
      templateKey

      body
      why_gladior
    }
  }
`;
